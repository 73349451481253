export default [
    {
        title: 'menu.dashboard',
        route: 'home',
        icon: 'HomeOutline',
    },
    {
        title: 'menu.posts',
        route: 'post-list',
        icon: 'NoteOutline',
        children: [
            {
                title: 'menu.list',
                route: 'posts',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'list',
                },
            },
            {
                title: 'menu.add',
                route: 'add-post',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'create',
                },
            },
        ],
        meta: {
            role: 'post',
        },
    },
    {
        title: 'menu.categories',
        route: 'category-list',
        icon: 'FolderOutline',
        children: [
            {
                title: 'menu.list',
                route: 'categories',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'list',
                },
            },
            {
                title: 'menu.add',
                route: 'add-category',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'create',
                },
            },
        ],
        meta: {
            role: 'category',
        },
    },
    {
        title: 'menu.tags',
        route: 'tag-list',
        icon: 'TagOutline',
        children: [
            {
                title: 'menu.list',
                route: 'tags',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'list',
                },
            },
            {
                title: 'menu.add',
                route: 'add-tag',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'create',
                },
            },
        ],
        meta: {
            role: 'tag',
        },
    },
    {
        title: 'menu.users',
        route: 'user-list',
        icon: 'AccountOutline',
        children: [
            {
                title: 'menu.list',
                route: 'users',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'list',
                },
            },
            {
                title: 'menu.add',
                route: 'add-user',
                icon: 'CheckboxBlankCircleOutline',
                meta: {
                    'role-method': 'create',
                },
            },
        ],
        meta: {
            role: 'user',
        },
    },
]
